<template>
  <v-card height="100%">
    <v-card-title v-t="title" />
    <v-card-text>
      <apexchart
        v-if="show"
        :options="options"
        :series="series"
      />
      <!-- Loading spinner -->
      <BaseSpinner v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ChartsWidget',
  components: {
    'apexchart': VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      show: false,
    };
  },
  mounted () {
    setTimeout(() => {
      this.show = true;
    }, 300);
  }
};
</script>
