<template>
  <WidgetTemplate
    :title="title"
    :loading="$apolloData.queries.datas.loading"
    :filters="filters"
    @updateFilters="updateFilters"
  >
    <v-data-table
      v-if="!$apolloData.queries.datas.loading"
      :headers="headers"
      :items="datas"
      :items-per-page="5"
      class="elevation-1"
    />
  </WidgetTemplate>
</template>

<script>
import gql from 'graphql-tag';
import filterMixins from '../mixins/filter';
import WidgetTemplate from './WidgetTemplate';

export default {
  name: 'DataTable',
  components: {
    WidgetTemplate,
  },
  mixins: [
    filterMixins,
  ],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    entity: {
      type: String,
      required: false,
      default: null,
    },
    query: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      show: false,
    };
  },
  apollo: {
    datas: {
      query () {
        return gql(`query { datas: ${this.formatedQuery} }`);
      },
      variables () {
       return this.gqlVariables;
      },
      skip () {
        return this.filtersLoading;
      },
    },
  },
  computed: {
    formatedQuery () {
      return this.query.replaceAll("${filter}", this.getFilterValue());
    },
    headers () {
      if (this.datas && this.datas.length > 0) {
        return Object.keys(this.datas[0]).map(header => {
          return {
            text: this.entity && this.$t(`${this.entity}.${header}`) !== `${this.entity}.${header}` ? this.$t(`${this.entity}.${header}`) : this.$t(header),
            value: header
          };
        });
      }
      return [];
    },
  },
  beforeDestroy () {
    this.$apollo.queries.datas.stop();
  },
};
</script>
