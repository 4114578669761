<template>
  <div class="pt-12">
    <v-row>
      <template v-for="(item, index) in items">
        <v-col
          :key="`dashboard-item-${index}`"
          :class="['py-0', item.col ? item.col : 'col-12 col-sm-6 col-lg-4']"
        >
          <component
            :is="item.component || item.name"
            ref="fieldElement"
            v-bind="{ ...item.props, ...$attrs }"
          />
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import ProfileDetails from '../components/ProfileDetails';
import RecentItems from '../components/RecentItems';
import Charts from '../components/Charts';
import ChartsArea from '../components/ChartsArea';
import DataTable from '../components/DataTable';
import ChartsStackedArea from '../components/ChartsStackedArea';

export default {
  name: 'Dashboard',
  components: {
    ProfileDetails,
    RecentItems,
    Charts,
    ChartsArea,
    DataTable,
    ChartsStackedArea,
  },
  computed: {
    items () {
      return this.$attrs.items || [];
    }
  },
};
</script>

<style>
.v-card__title {
  word-break: normal !important;
}
</style>
