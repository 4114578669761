<template>
  <v-card
    v-if="userId !== null && !$apolloData.queries.details.loading"
    height="100%"
  >
    <v-card-text>
      <!-- Titles -->
      <div>{{ $t('dashboard.welcome') }},</div>
      <p class="display-1 text--primary">
        {{ userName }}
      </p>
      <div>{{ $t('dashboard.your_profile') }}:</div>
      <!-- Content -->
      <template
        v-if="details"
      >
        <template
          v-for="(row) in rows"
        >
          <div
            :key="row"
            class="text--primary"
          >
            {{ $t(`user.${row}`) }}:
            <strong>{{ details[row] ? details[row] : row }}</strong>
          </div>
        </template>
      </template>
      <BaseSpinner v-else />
    </v-card-text>
  </v-card>
  <!-- Loading spinner -->
  <BaseSpinner v-else />
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'ProfileDetailsWidget',
  data () {
    return {
      rows: [
        'firstname',
        'lastname',
        'email',
        'function',
        'gender',
        'mobile',
        'phone',
        'last_login_date'
      ],
    };
  },
  apollo: {
    details: {
      query () {
        const query = `
          query item ($id: Int!) {
            details: user(id: $id) {
              ${this.rows}
            }
          }
        `;
        return gql(query);
      },
      variables () {
        return {
          id: this.userId,
        };
      },
      skip () {
        return !this.userId;
      },
    },
  },
  destroyed () {
    this.$_apollo.queries.details.stop();
  }
};
</script>
