<template>
  <v-card
    v-if="userId !== null && !$apolloData.queries.rows.loading"
    height="100%"
  >
    <v-card-text>
      <!-- Content -->
      <div>{{ $t('dashboard.recent_items') }}:</div>
      <div
        v-for="(row, i) in rows"
        :key="i"
        class="text--primary"
      >
        <span
          v-for="(field, l) in fields"
          :key="l"
        >
          <strong>{{ row[field] }}</strong>
        </span>
      </div>
    </v-card-text>
  </v-card>
  <!-- Loading spinner -->
  <BaseSpinner v-else />
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'RecentItemsWidget',
  props: {
    type: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  apollo: {
    rows: {
      query () {
        return this.listingQuery;
      },
    },
  },
  computed: {
    listingQuery () {
      const itemsPerPage = 5;
      const offset = 0;
      const query = `{
        rows: ${this.type} (offset: ${offset}, limit: ${itemsPerPage}) {
          ${this.fields.map(field => field)}
        }
      }`;
      return gql(query);
    },
  },
};
</script>
